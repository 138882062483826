import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import pvtFetcher, { PvtFetcherProps } from '../../utils/pvtFetcher';
import { BasicDevelopmentAnalyticsData } from '../types';
import API_ENDPOINTS from '../../endpoints';
import { PaginationProperties } from '../../utils/types';

export interface GetBasicDevelopmentAnalyticsResponse {
  data: Record<string, BasicDevelopmentAnalyticsData>;
  meta: {
    total: number; // Total records in database (for pagination purposes)
  };
}

export interface UseGetBasicDevelopmentAnalyticsProps
  extends PvtFetcherProps,
    Partial<PaginationProperties> {
  queryConfig?: UseQueryOptions<GetBasicDevelopmentAnalyticsResponse, Error>;
}

export default function useGetBasicDevelopmentsAnalytics({
  authToken,
  orgId,
  page = 1,
  itemsPerPage = 10,
  queryConfig,
}: UseGetBasicDevelopmentAnalyticsProps) {
  const url = API_ENDPOINTS.BASIC_DEVELOPMENTS_ANALYTICS;

  const body = {
    pagination: { itemsPerPage, page },
  };

  return useQuery<GetBasicDevelopmentAnalyticsResponse, Error>(
    [url, { authToken, orgId, itemsPerPage, page }],
    () =>
      pvtFetcher(url, {
        method: 'POST',
        authToken,
        orgId,
        body: JSON.stringify(body),
      }),
    queryConfig
  );
}
