import * as React from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import useGetAnalyticsBasic from '../../../../api/analytics/useGetAnalyticsBasic/useGetAnalyticsBasic';
import useAuthToken from '../../../../store/auth/hooks/useAuthToken';

// ========== TYPES ========== //

interface ComponentProps {
  orgId: number;
}

// ========== COMPONENT ========== //

export default function AnalyticsBasic({ orgId }: ComponentProps) {
  const authToken = useAuthToken();

  const { data } = useGetAnalyticsBasic({ authToken, orgId });

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>City</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>Number of developments</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(data?.data?.developments?.cities ?? []).map(
                ([city, count]) => (
                  <TableRow key={city}>
                    <TableCell component="th" scope="row">
                      {city}
                    </TableCell>
                    <TableCell align="right">{count}</TableCell>
                  </TableRow>
                )
              )}
              <TableRow>
                <TableCell component="th" scope="row">
                  <strong>Total</strong>
                </TableCell>
                <TableCell align="right">
                  {data?.data?.developments?.count}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={4}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Flat type</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>Number of flats</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  BTR
                </TableCell>
                <TableCell align="right">
                  {data?.data?.flats?.btr?.count}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Co-living
                </TableCell>
                <TableCell align="right">
                  {data?.data?.flats?.coLiving?.count}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={4}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong />
                </TableCell>
                <TableCell align="right">
                  <strong>Number of landlords</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Landlord
                </TableCell>
                <TableCell align="right">
                  {data?.data?.landlords?.count}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
