import * as React from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import SEO from '../components/atoms/SEO';
import GenericPage from '../components/layout/CommonLayout/GenericPage';
import useAuthToken from '../store/auth/hooks/useAuthToken';
import useMyUserDetails from '../api/auth/me/useMyUserDetails';
import AnalyticsBasic from '../components/pages/analytics/AnalyticsBasic';
import DevelopmentsAnalytics from '../components/pages/analytics/DevelopmentsAnalytics';

export default function Analytics() {
  const authToken = useAuthToken();
  const { data: myUserDetails } = useMyUserDetails({
    authToken,
  });

  return (
    <GenericPage>
      <SEO title="Analytics" />
      <Box pb={3}>
        <Container>
          <Box pt={3} pb={3}>
            <Typography variant="h4">Analytics Basic</Typography>
          </Box>
          {myUserDetails && myUserDetails.user.organization_ids.length > 0 ? (
            myUserDetails.user.organization_ids.map((orgId) => (
              <Box key={orgId}>
                <AnalyticsBasic orgId={orgId} />
                <DevelopmentsAnalytics orgId={orgId} />
              </Box>
            ))
          ) : (
            <Typography>There is nothing here.</Typography>
          )}
        </Container>
      </Box>
    </GenericPage>
  );
}
