import * as React from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import useAuthToken from '../../../../store/auth/hooks/useAuthToken';
import useGetBasicDevelopmentsAnalytics from '../../../../api/analytics/useGetBasicDevelopmentsAnalytics/useGetBasicDevelopmentsAnalytics';
import { formatNumber } from '../../../../utils/number';

// ========== TYPES ========== //

interface ComponentProps {
  orgId: number;
}

// ========== COMPONENT ========== //

export default function DevelopmentsAnalytics({ orgId }: ComponentProps) {
  const authToken = useAuthToken();
  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState<
    5 | 10 | 20 | 25 | 50 | 100
  >(10);

  const { data: developmentData, isLoading: developmentLoading } =
    useGetBasicDevelopmentsAnalytics({
      authToken,
      orgId,
      page: page + 1,
      itemsPerPage,
    });

  const handleChangePage = (_, page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    setItemsPerPage(
      parseInt(event.target.value, 10) as 5 | 10 | 20 | 25 | 50 | 100
    );
    setPage(0);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box pt={3} pb={3}>
          <Typography variant="h4">Developments Analytics</Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Development ID</strong>
                </TableCell>
                <TableCell>
                  <strong>Development Title</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>View count</strong>
                  <br /> (lifetime)
                </TableCell>
                <TableCell align="center">
                  <strong>View count</strong>
                  <br /> (today)
                </TableCell>
                <TableCell align="center">
                  <strong>View count</strong>
                  <br /> (7 days)
                </TableCell>
                <TableCell align="center">
                  <strong>View count</strong>
                  <br /> (30 days)
                </TableCell>
                <TableCell align="center">
                  <strong>Average engagement time</strong>
                  <br /> (lifetime)
                  <br /> (seconds)
                </TableCell>
                <TableCell align="center">
                  <strong>Average engagement time</strong>
                  <br /> (today)
                  <br /> (seconds)
                </TableCell>
                <TableCell align="center">
                  <strong>Average engagement time</strong>
                  <br /> (7 days)
                  <br /> (seconds)
                </TableCell>
                <TableCell align="center">
                  <strong>Average engagement time</strong>
                  <br /> (30 days)
                  <br /> (seconds)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {developmentLoading ? (
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                developmentData?.data &&
                Object.values(developmentData.data).map(
                  ({
                    developmentId,
                    title,
                    developmentViewCount,
                    developmentViewCountToday,
                    developmentViewCount7day,
                    developmentViewCount30day,
                    developmentAverageEngagementTime,
                    developmentAverageEngagementTimeToday,
                    developmentAverageEngagementTime7day,
                    developmentAverageEngagementTime30day,
                  }) => (
                    <TableRow key={developmentId}>
                      <TableCell>{developmentId}</TableCell>
                      <TableCell>{title}</TableCell>
                      <TableCell align="right">
                        {developmentViewCount ?? 0}
                      </TableCell>
                      <TableCell align="right">
                        {developmentViewCountToday ?? 0}
                      </TableCell>
                      <TableCell align="right">
                        {developmentViewCount7day ?? 0}
                      </TableCell>
                      <TableCell align="right">
                        {developmentViewCount30day ?? 0}
                      </TableCell>
                      <TableCell align="right">
                        {developmentAverageEngagementTime
                          ? formatNumber(developmentAverageEngagementTime, {
                              decimalPlaces: 2,
                            })
                          : 0}
                      </TableCell>
                      <TableCell align="right">
                        {developmentAverageEngagementTimeToday
                          ? formatNumber(
                              developmentAverageEngagementTimeToday,
                              {
                                decimalPlaces: 2,
                              }
                            )
                          : 0}
                      </TableCell>
                      <TableCell align="right">
                        {developmentAverageEngagementTime7day
                          ? formatNumber(developmentAverageEngagementTime7day, {
                              decimalPlaces: 2,
                            })
                          : 0}
                      </TableCell>
                      <TableCell align="right">
                        {developmentAverageEngagementTime30day
                          ? formatNumber(
                              developmentAverageEngagementTime30day,
                              {
                                decimalPlaces: 2,
                              }
                            )
                          : 0}
                      </TableCell>
                    </TableRow>
                  )
                )
              )}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
            component="div"
            count={developmentData?.meta.total ?? 0}
            rowsPerPage={itemsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Grid>
    </Grid>
  );
}
