import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from '../../endpoints';
import pvtFetcher from '../../utils/pvtFetcher';

interface FetcherResponse {
  data: {
    developments: {
      count: number;
      cities: Record<string, number>;
    };
    flats: {
      btr: { count: number };
      coLiving: { count: number };
    };
    landlords: {
      count: number;
    };
  };
}

export default function useGetAnalyticsBasic({
  authToken,
  orgId,
  queryConfig,
}: {
  authToken: string;
  orgId: number;
  queryConfig?: UseQueryOptions<any>;
}) {
  const url = API_ENDPOINTS.BASIC_PROPERTIES_ANALYTICS;

  return useQuery<FetcherResponse, Error>(
    [url, { authToken, orgId }],
    () => pvtFetcher(url, { method: 'POST', authToken, orgId }),
    queryConfig
  );
}
